import request from '../request.js';
import public_request from '../public_request';
// 线索数据列表的方法
export function importClues(data) {
    return request({
        url: '/data-management/clues',
        method: 'GET',
        params: public_request(data),
    });
}
// 移除上传线索
export function removeClue(data) {
    return request({
        url: '/data-management/clues',
        method: 'DELETE',
        data: public_request(data),
    });
}
// 移除上传老线索
export function removeClueExist(data) {
    return request({
        url: '/data-management/clues-exist',
        method: 'DELETE',
        data: public_request(data),
    });
}
// 移除上传无法导入线索
export function removeClueInvalid(data) {
    return request({
        url: '/data-management/clues-invalid',
        method: 'DELETE',
        data: public_request(data),
    });
}
// 线索一键去重
export function removeClueDuplicate(data) {
    return request({
        url: '/data-management/clues-duplicate',
        method: 'DELETE',
        data: public_request(data),
    });
}
// 单条导入上传线索
export function singeImportClue(data) {
    return request({
        url: '/data-management/clues',
        method: 'post',
        data: public_request(data),
    });
}
// 全部导入上传线索
export function allImportClue(data) {
    return request({
        url: '/data-management/clues-all',
        method: 'POST',
        data: public_request(data),
    });
}
